.about-section {
    padding: 50px 15px;
    text-align: center;
    background-color: #000000; /* Light background to differentiate from other sections, if you prefer */
  }
  
  .about-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-section p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
 

.about-buttons {
    margin-top: 30px;
  }
  

  