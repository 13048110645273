/* Projects.css */

.project-card {
    margin-bottom: 20px;
  }
  
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    width: calc(33.33% - 20px); /* Adjust card width as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card img {
    max-height: 200px; /* Set maximum height for images */
    object-fit: cover;
  }
  
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  /* Responsive styles for mobile devices */
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }


  .project-title {
    font-family: 'Teko', sans-serif;
    font-size: 35px;
  }
  