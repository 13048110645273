/* Contact.css */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
}

.contact-header {
    font-size: 2em;
    margin-bottom: 20px;
}

.contact-subheader {
    font-size: 1.2em;
    margin-bottom: 40px;
}

.form-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-field {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1em;
}

.textarea-field {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1em;
    height: 150px;
    resize: vertical;
}

.submit-button {
    background-color: #333;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #555;
}

/* Contact.css */
.custom-toast {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  
  .custom-toast img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  