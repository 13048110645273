/* App.css or your global css file */
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400&display=swap');

/* Add to App.css */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Optional but can help with layout issues */
}


body, html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* If you have a main content wrapper, give it flex grow */
.main-content {
  flex-grow: 1;
}

.footer {
  width: 100%; /* Ensures the footer takes the full width */
  flex-shrink: 0;
  position: relative; /* This can help if any child elements of the footer are positioned absolutely */
}


.navbar-brand {
  font-family: 'Teko', sans-serif;
  font-size:xx-large;
}