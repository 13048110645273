/* Parallax Effect */
.parallax-section {
    height: 100vh; /* Take the full viewport height */
    background-color: black;
    background-image: url('../images/zuelchlogo.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .parallax-content {
    text-align: center;
    color: white; /* Change as required based on your image */
  
    /* Optional: Add a background to make the text stand out more against the image */
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    border-radius: 10px;
  }
  
  